var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-block"},[_c('b-tabs',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"1. ข้อมูลส่วนตัว","active":""}},[_c('div',{staticClass:"p-3",class:{ 'register-box': !_vm.isMobile }},[_c('b-row',[_vm._l((_vm.fields.filter((el) => el.group_id == 1)),function(profile){return [(profile.field_profile_type_id !== 22)?_c('b-col',{key:'field-' + profile.name,attrs:{"md":"6"}},[_c('div',{attrs:{"set":(_vm.field =
                      _vm.$v.form[_vm.checkIsProfile(profile.field_profile_type_id)]),"id":'field-' + profile.profile_name}},[(profile.field_type_id == 1)?_c('b-row',[_c('b-col',[_c('InputText',{staticClass:"margin-inputf-regular",class:{
                          'mb-2': profile.field_profile_type_id !== 10,
                        },attrs:{"textFloat":_vm.changeName(profile),"placeholder":_vm.changeName(profile),"type":_vm.checkFieldConditon(profile),"name":profile.profile_name,"isRequired":_vm.checkRequired(
                            _vm.checkIsProfile(profile.field_profile_type_id)
                          ),"isValidate":_vm.field.$error,"v":_vm.field},model:{value:(
                          _vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]
                        ),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"\n                          form[checkIsProfile(profile.field_profile_type_id)]\n                        "}}),(profile.field_profile_type_id == 10)?_c('p',{staticClass:"text-right text-link mb-2",on:{"click":function($event){return _vm.addTel()}}},[_vm._v(" เบอร์โทรศัพท์สำรอง ")]):_vm._e()],1)],1):(
                      profile.field_profile_type_id == 2 ||
                      profile.field_profile_type_id == 21
                    )?_c('b-row',[_c('b-col',[_c('InputSelect',{attrs:{"title":profile.name,"placeholder":profile.name,"options":profile.field_choices.map((x) => ({
                            text: x.display_name,
                            value: x.name,
                          })),"className":"mb-2","validateText":profile.validate_text,"isValidate":_vm.field.$error,"v":_vm.field},on:{"onDataChange":(val) =>
                            _vm.handleSelected(
                              val,
                              _vm.checkIsProfile(profile.field_profile_type_id),
                              profile.field_choices
                            )},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- "+_vm._s(profile.name)+" --")])]},proxy:true}],null,true),model:{value:(
                          _vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]
                        ),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"\n                          form[checkIsProfile(profile.field_profile_type_id)]\n                        "}})],1),(
                        _vm.isOtherPrefix && profile.field_profile_type_id == 2
                      )?_c('b-col',[_c('InputText',{attrs:{"className":"mb-2","textFloat":" ","placeholder":profile.field_choices.find(
                            (x) => x.choice_type_id == 3
                          ).description},model:{value:(_vm.otherPrefix),callback:function ($$v) {_vm.otherPrefix=$$v},expression:"otherPrefix"}})],1):(
                        _vm.isOtherGender && profile.field_profile_type_id == 21
                      )?_c('b-col',[_c('InputText',{attrs:{"className":"mb-2","textFloat":" ","placeholder":profile.field_choices.find(
                            (x) => x.choice_type_id == 3
                          ).description},model:{value:(_vm.otherGender),callback:function ($$v) {_vm.otherGender=$$v},expression:"otherGender"}},[_vm._v(" > ")])],1):_vm._e()],1):(profile.field_type_id == 7)?_c('div',{staticClass:"datetime-container"},[_c('datetime',{ref:'data-picker' + profile.name,refInFor:true,staticClass:"datetime-h mb-2",class:[
                        'date-picker-input',
                        'date-filter',
                        {
                          'border-red': _vm.field.$error,
                        },
                      ],attrs:{"type":"date","textFloat":"วันเกิด ","name":"birthday","placeholder":"วันเกิด","format":'dd/MM/yyyy',"value-zone":"Asia/Bangkok","maxDatetime":_vm.now,"validateText":_vm.field.validate_text,"isValidate":_vm.field.$error},on:{"input":(val) =>
                          (_vm.form[
                            _vm.checkIsProfile(profile.field_profile_type_id)
                          ] = val)},model:{value:(
                        _vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]
                      ),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"\n                        form[checkIsProfile(profile.field_profile_type_id)]\n                      "}},[_c('label',{attrs:{"slot":"before","for":"startDate"},slot:"before"},[_vm._v(_vm._s(profile.name)+" "),(
                            _vm.checkRequired(
                              _vm.checkIsProfile(profile.field_profile_type_id)
                            )
                          )?_c('span',{staticClass:"text-danger"},[_vm._v("* ")]):_vm._e()]),_c('font-awesome-icon',{attrs:{"icon":"calendar-alt"}}),(_vm.field.$error)?_c('span',{staticClass:"description text-danger text-error",attrs:{"slot":"after"},slot:"after"},[_vm._v("กรุณากรอกข้อมูล ")]):_vm._e()],1),_c('div',{staticClass:"calendar-icon",class:{
                          'calendar-icon-top': _vm.field.$error,
                        },on:{"click":function($event){_vm.$refs['date-picker' + profile.name].showCalendar()}}},[_c('font-awesome-icon',{attrs:{"icon":"calendar-alt"}})],1)],1):(profile.field_type_id == 8)?_c('div',[(_vm.changeName(profile))?_c('label',[_vm._v(" "+_vm._s(_vm.changeName(profile))+" ")]):_vm._e(),_c('InputUploadImage',{attrs:{"id":profile.id,"label":_vm.changeName(profile),"img":_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)],"v":_vm.field,"validateText":_vm.field.validate_text,"isValidate":_vm.field.$error},on:{"setFileName":(val) =>
                          _vm.setImageName(val, profile.field_profile_type_id)},model:{value:(
                        _vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]
                      ),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"\n                        form[checkIsProfile(profile.field_profile_type_id)]\n                      "}}),_c('div',{staticClass:"f-12 color-gray mt-1 mb-2"},[_vm._v(" ไฟล์ที่อัพโหลดต้องเป็นไฟล์นามสกุล PDF, PNG ,JPG ขนาดไม่เกิน 2.5 mb เท่านั้น ")])],1):_vm._e()],1)]):_vm._e()]})],2)],1)]),_c('b-tab',{attrs:{"title":"2. ข้อมูลที่อยู่"}},[_c('div',{staticClass:"p-3",class:{ 'register-box': !_vm.isMobile }},[_c('b-row',[_vm._l((_vm.fields.filter((el) => el.group_id == 2)),function(profile){return _c('b-col',{attrs:{"md":"6"}},[(profile.field_profile_type_id == 24)?_c('InputSelect',{attrs:{"title":profile.name,"placeholder":profile.name,"name":profile.name,"options":profile.field_choices.map((x) => ({
                    text: x.name,
                    value: x.name,
                  })),"className":"mb-2"},on:{"onDataChange":(val) => _vm.handleChangeCountry(val)},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- ประเทศ --")])]},proxy:true}],null,true),model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}):_vm._e(),(_vm.form.country === 'ประเทศไทย')?_c('div',[(
                    profile.field_type_id == 1 &&
                    profile.field_profile_type_id !== 19 &&
                    profile.field_profile_type_id !== 18 &&
                    profile.field_profile_type_id !== 17 &&
                    profile.field_profile_type_id !== 16
                  )?_c('InputText',{attrs:{"placeholder":profile.name,"textFloat":profile.name,"className":"mb-2"},model:{value:(
                    _vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]
                  ),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"\n                    form[checkIsProfile(profile.field_profile_type_id)]\n                  "}}):(
                    _vm.checkIsProfile(profile.field_profile_type_id) ===
                    'province'
                  )?_c('InputSelect',{attrs:{"title":"จังหวัด","placeholder":"จังหวัด","name":"province","className":"mb-2","options":_vm.province.map((x) => ({ text: x.name, value: x.name }))},on:{"onDataChange":(val) => _vm.handleChange(val, 'province')},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- จังหวัด --")])]},proxy:true}],null,true),model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}}):(
                    _vm.checkIsProfile(profile.field_profile_type_id) ===
                    'district'
                  )?_c('InputSelect',{attrs:{"title":"เขต","placeholder":"เขต","name":"district","className":"mb-2","options":_vm.district.map((x) => ({ text: x.name, value: x.name })),"disabled":!_vm.form.province},on:{"onDataChange":(val) => _vm.handleChange(val, 'district')},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- เขต --")])]},proxy:true}],null,true),model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}}):(
                    _vm.checkIsProfile(profile.field_profile_type_id) ===
                    'subdistrict'
                  )?_c('InputSelect',{attrs:{"title":"แขวง","placeholder":"แขวง","name":"subdistrict","className":"mb-2","options":_vm.subdistrict.map((x) => ({ text: x.name, value: x.name })),"disabled":!_vm.form.district},on:{"onDataChange":(val) => _vm.handleChange(val, 'subdistrict')},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- แขวง --")])]},proxy:true}],null,true),model:{value:(_vm.form.subdistrict),callback:function ($$v) {_vm.$set(_vm.form, "subdistrict", $$v)},expression:"form.subdistrict"}}):(
                    _vm.checkIsProfile(profile.field_profile_type_id) ===
                    'zip_code'
                  )?_c('InputText',{ref:"postNum",refInFor:true,attrs:{"textFloat":"รหัสไปรษณีย์","placeholder":"รหัสไปรษณีย์","name":"zip_code","className":"mb-2","isValidate":_vm.$v.form.zip_code.$error,"v":_vm.$v.form.zip_code,"disabled":true},on:{"onEnter":function($event){return _vm.handleEnter(1)},"onKeypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.form.zip_code),callback:function ($$v) {_vm.$set(_vm.form, "zip_code", $$v)},expression:"form.zip_code"}}):_vm._e()],1):_vm._e()],1)}),(_vm.form.country === 'ต่างประเทศ')?_c('b-col',{attrs:{"cols":"12"}},[_c('InputTextArea',{attrs:{"textFloat":"บ้านเลขที่","placeholder":"บ้านเลขที่","name":"text","rows":"3"},model:{value:(_vm.form.home_address),callback:function ($$v) {_vm.$set(_vm.form, "home_address", $$v)},expression:"form.home_address"}})],1):_vm._e()],2)],1)]),(_vm.dynamicField.length > 0)?_c('b-tab',{attrs:{"title":"3. ข้อมูลเพิ่มเติม"}},[_c('DynamicFields',{attrs:{"isMobile":_vm.isMobile,"form":_vm.form,"dynamicField":_vm.dynamicField,"keyRequired":"isRequired"}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }