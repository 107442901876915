<template>
  <div>
    <div class="d-block">
      <b-tabs v-model="tabIndex">
        <b-tab title="1. ข้อมูลส่วนตัว" active>
          <div class="p-3" :class="{ 'register-box': !isMobile }">
            <b-row>
              <template
                v-for="profile in fields.filter((el) => el.group_id == 1)"
              >
                <b-col
                  md="6"
                  v-if="profile.field_profile_type_id !== 22"
                  :key="'field-' + profile.name"
                >
                  <div
                    :set="
                      (field =
                        $v.form[checkIsProfile(profile.field_profile_type_id)])
                    "
                    :id="'field-' + profile.profile_name"
                  >
                    <b-row v-if="profile.field_type_id == 1">
                      <b-col>
                        <InputText
                          :textFloat="changeName(profile)"
                          :placeholder="changeName(profile)"
                          class="margin-inputf-regular"
                          :type="checkFieldConditon(profile)"
                          :name="profile.profile_name"
                          :isRequired="
                            checkRequired(
                              checkIsProfile(profile.field_profile_type_id)
                            )
                          "
                          :class="{
                            'mb-2': profile.field_profile_type_id !== 10,
                          }"
                          :isValidate="field.$error"
                          :v="field"
                          v-model:value="
                            form[checkIsProfile(profile.field_profile_type_id)]
                          "
                        />
                        <p
                          class="text-right text-link mb-2"
                          @click="addTel()"
                          v-if="profile.field_profile_type_id == 10"
                        >
                          เบอร์โทรศัพท์สำรอง
                        </p>
                      </b-col>
                    </b-row>

                    <b-row
                      v-else-if="
                        profile.field_profile_type_id == 2 ||
                        profile.field_profile_type_id == 21
                      "
                    >
                      <b-col>
                        <InputSelect
                          :title="profile.name"
                          :placeholder="profile.name"
                          v-model="
                            form[checkIsProfile(profile.field_profile_type_id)]
                          "
                          :options="
                            profile.field_choices.map((x) => ({
                              text: x.display_name,
                              value: x.name,
                            }))
                          "
                          className="mb-2"
                          @onDataChange="
                            (val) =>
                              handleSelected(
                                val,
                                checkIsProfile(profile.field_profile_type_id),
                                profile.field_choices
                              )
                          "
                          :validateText="profile.validate_text"
                          :isValidate="field.$error"
                          :v="field"
                        >
                          <template v-slot:option-first>
                            <b-form-select-option :value="null" disabled
                              >-- {{ profile.name }} --</b-form-select-option
                            >
                          </template></InputSelect
                        ></b-col
                      >

                      <b-col
                        v-if="
                          isOtherPrefix && profile.field_profile_type_id == 2
                        "
                      >
                        <InputText
                          className="mb-2"
                          textFloat=" "
                          v-model="otherPrefix"
                          :placeholder="
                            profile.field_choices.find(
                              (x) => x.choice_type_id == 3
                            ).description
                          "
                        >
                        </InputText
                      ></b-col>

                      <b-col
                        v-else-if="
                          isOtherGender && profile.field_profile_type_id == 21
                        "
                      >
                        <InputText
                          className="mb-2"
                          textFloat=" "
                          v-model="otherGender"
                          :placeholder="
                            profile.field_choices.find(
                              (x) => x.choice_type_id == 3
                            ).description
                          "
                        >
                          >
                        </InputText></b-col
                      >
                    </b-row>
                    <div
                      v-else-if="profile.field_type_id == 7"
                      class="datetime-container"
                    >
               
                      <datetime
                        type="date"
                        textFloat="วันเกิด "
                        name="birthday"
                        :ref="'data-picker' + profile.name"
                        placeholder="วันเกิด"
                        :format="'dd/MM/yyyy'"
                        :class="[
                          'date-picker-input',
                          'date-filter',
                          {
                            'border-red': field.$error,
                          },
                        ]"
                        value-zone="Asia/Bangkok"
                        v-model="
                          form[checkIsProfile(profile.field_profile_type_id)]
                        "
                        @input="
                          (val) =>
                            (form[
                              checkIsProfile(profile.field_profile_type_id)
                            ] = val)
                        "
                        :maxDatetime="now"
                        class="datetime-h mb-2"
                        :validateText="field.validate_text"
                        :isValidate="field.$error"
                      >
                        <label for="startDate" slot="before"
                          >{{ profile.name }}
                          <span
                            class="text-danger"
                            v-if="
                              checkRequired(
                                checkIsProfile(profile.field_profile_type_id)
                              )
                            "
                            >*
                          </span>
                        </label>
                        <font-awesome-icon icon="calendar-alt" />
                        <span
                          class="description text-danger text-error"
                          slot="after"
                          v-if="field.$error"
                          >กรุณากรอกข้อมูล
                        </span>
                      </datetime>
                      <div
                        class="calendar-icon" 
                         :class="
                          {
                            'calendar-icon-top': field.$error,
                          }
                        "
                        @click="
                          $refs['date-picker' + profile.name].showCalendar()
                        "
                      >
                        <font-awesome-icon icon="calendar-alt" />
                      </div>
                    </div>

                    <div v-else-if="profile.field_type_id == 8">
                      <label v-if="changeName(profile)">
                        {{ changeName(profile) }}
                      </label>

                      <InputUploadImage
                        :id="profile.id"
                        :label="changeName(profile)"
                        :img="
                          form[checkIsProfile(profile.field_profile_type_id)]
                        "
                        v-model="
                          form[checkIsProfile(profile.field_profile_type_id)]
                        "
                        :v="field"
                        @setFileName="
                          (val) =>
                            setImageName(val, profile.field_profile_type_id)
                        "
                        :validateText="field.validate_text"
                        :isValidate="field.$error"
                      />
                      <div class="f-12 color-gray mt-1 mb-2">
                        ไฟล์ที่อัพโหลดต้องเป็นไฟล์นามสกุล PDF, PNG ,JPG
                        ขนาดไม่เกิน 2.5 mb เท่านั้น
                      </div>
                    </div>
                  </div>
                </b-col>
              </template>
            </b-row>
          </div>
        </b-tab>

        <b-tab title="2. ข้อมูลที่อยู่">
          <div class="p-3" :class="{ 'register-box': !isMobile }">
            <b-row>
              <b-col
                md="6"
                v-for="profile in fields.filter((el) => el.group_id == 2)"
              >
                <InputSelect
                  v-if="profile.field_profile_type_id == 24"
                  :title="profile.name"
                  :placeholder="profile.name"
                  :name="profile.name"
                  v-model="form[checkIsProfile(profile.field_profile_type_id)]"
                  :options="
                    profile.field_choices.map((x) => ({
                      text: x.name,
                      value: x.name,
                    }))
                  "
                  className="mb-2"
                  @onDataChange="(val) => handleChangeCountry(val)"
                >
                  <template v-slot:option-first>
                    <b-form-select-option :value="null" disabled
                      >-- ประเทศ --</b-form-select-option
                    >
                  </template>
                </InputSelect>
                <div v-if="form.country === 'ประเทศไทย'">
                  <InputText
                    v-if="
                      profile.field_type_id == 1 &&
                      profile.field_profile_type_id !== 19 &&
                      profile.field_profile_type_id !== 18 &&
                      profile.field_profile_type_id !== 17 &&
                      profile.field_profile_type_id !== 16
                    "
                    :placeholder="profile.name"
                    :textFloat="profile.name"
                    v-model="
                      form[checkIsProfile(profile.field_profile_type_id)]
                    "
                    className="mb-2"
                  />
                  <InputSelect
                    v-else-if="
                      checkIsProfile(profile.field_profile_type_id) ===
                      'province'
                    "
                    title="จังหวัด"
                    placeholder="จังหวัด"
                    name="province"
                    v-model="form.province"
                    className="mb-2"
                    :options="
                      province.map((x) => ({ text: x.name, value: x.name }))
                    "
                    @onDataChange="(val) => handleChange(val, 'province')"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- จังหวัด --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                  <InputSelect
                    v-else-if="
                      checkIsProfile(profile.field_profile_type_id) ===
                      'district'
                    "
                    title="เขต"
                    placeholder="เขต"
                    name="district"
                    v-model="form.district"
                    className="mb-2"
                    :options="
                      district.map((x) => ({ text: x.name, value: x.name }))
                    "
                    :disabled="!form.province"
                    @onDataChange="(val) => handleChange(val, 'district')"
                    ><template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- เขต --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                  <InputSelect
                    v-else-if="
                      checkIsProfile(profile.field_profile_type_id) ===
                      'subdistrict'
                    "
                    title="แขวง"
                    placeholder="แขวง"
                    name="subdistrict"
                    v-model="form.subdistrict"
                    className="mb-2"
                    :options="
                      subdistrict.map((x) => ({ text: x.name, value: x.name }))
                    "
                    :disabled="!form.district"
                    @onDataChange="(val) => handleChange(val, 'subdistrict')"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- แขวง --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                  <InputText
                    v-else-if="
                      checkIsProfile(profile.field_profile_type_id) ===
                      'zip_code'
                    "
                    ref="postNum"
                    @onEnter="handleEnter(1)"
                    textFloat="รหัสไปรษณีย์"
                    placeholder="รหัสไปรษณีย์"
                    name="zip_code"
                    v-model="form.zip_code"
                    className="mb-2"
                    :isValidate="$v.form.zip_code.$error"
                    :v="$v.form.zip_code"
                    :disabled="true"
                    @onKeypress="isNumber($event)"
                  />
                </div>
              </b-col>
              <b-col v-if="form.country === 'ต่างประเทศ'" cols="12">
                <InputTextArea
                  textFloat="บ้านเลขที่"
                  placeholder="บ้านเลขที่"
                  name="text"
                  rows="3"
                  v-model="form.home_address"
                />
              </b-col>
            </b-row></div
        ></b-tab>

        <b-tab v-if="dynamicField.length > 0" title="3. ข้อมูลเพิ่มเติม">
          <DynamicFields
            :isMobile="isMobile"
            :form="form"
            :dynamicField="dynamicField"
            keyRequired="isRequired"
          ></DynamicFields>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import InputSelect from "@/components/inputs/InputSelect";
import { fieldInForm2, profile_fields2 } from "@/assets/json/fieldProfile.js";
import { province } from "@/assets/json/fieldAddress.js";
import InputTextArea from "@/components/inputs/InputTextArea";

import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import InputUploadImage from "../inputs/InputUploadImage.vue";
import DynamicFields from "./DynamicFields.vue";

// const Script_checkID = (id) => {
//   console.log(id);
//   let i = 0,
//     sum = 0;

//   if (id.substring(0, 1) == 0) return false;
//   for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
//   if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
//   return true;
// };

export default {
  components: {
    // province,
    InputSelect,
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    InputUploadImage,
    InputTextArea,
    DynamicFields,
  },
  props: {
    form: {
      type: Object,
      default: true,
    },
    isEdit: {
      type: Boolean,
    },
    isMobile: {
      type: Boolean,
    },
    fields: {
      type: Array,
    },
    dynamicField: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      now: null,
      province,
      country: "ประเทศไทย",
      district: [],
      subdistrict: [],
      birthday: "",
      tabIndex: 0,
      isOtherPrefix: false,
      otherPrefix: "",
      isOtherGender: false,
      otherGender: "",
    };
  },

  validations() {
    for (const item of this.fields) {
      const key = this.checkIsProfile(item.field_profile_type_id);

      if (key) {
        if (
          key === "birthday" ||
          key === "first_name_th" ||
          key === "last_name_th" ||
          key === "telephone"
        ) {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { required, email };
          } else if (item.field_profile_type_id == 10)
            profile_fields2[key] = {
              required,
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric,
            };
          else if (item.field_profile_type_id == 9) {
            profile_fields2["birthday"] = {
              required,
            };
          } else profile_fields2[key] = { required };
        } else {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { email };
          } else if (item.field_profile_type_id == 1)
            profile_fields2[key] = {
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric,
            };
          else profile_fields2[key] = {};
        }
      }
    }

    return {
      form: profile_fields2,
    };
  },
  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.form.country = this.form.country == ("" || null )? "ประเทศไทย" : this.form.country;
    if (this.isEdit) {
      this.form.birthday = new Date(this.form.birthday).toISOString();
      let prefixFind = this.fields.find((x) => x.field_profile_type_id == 2);
      let prefixField = prefixFind ? prefixFind.field_choices : null;
      let prefixOther = prefixField
        ? prefixField.find((x) => x.choice_type_id == 3)
        : [];
      let genderFind = this.fields.find((x) => x.field_profile_type_id == 21);

      let genderField = genderFind ? genderFind.field_choices : null;
      let genderOther = genderFind
        ? genderField.find((x) => x.choice_type_id == 3)
        : [];
      let optionsPrefix = prefixOther ? prefixOther.name : [];
      let optionsGender = genderOther ? genderOther.name : [];
      console.log(optionsGender, optionsPrefix);
      if (prefixField)
        if (
         ( !prefixField.map((x) => x.name).includes(this.form.prefix) &&
          this.form.prefix !== null) || this.form.prefix.includes('อื่นๆ')
        ) {
          this.otherPrefix = this.form.prefix;
          this.form.prefix = optionsPrefix;
          this.isOtherPrefix = true;
        }

      if (genderField) {
        if (this.form.gender == "N/A") {
          this.form.gender = null;
        }

        if (
         ( !genderField.map((x) => x.name).includes(this.form.gender) &&
          this.form.gender !== null )|| this.form.gender.includes('อื่นๆ')
        ) {
          this.otherGender = this.form.gender;
          this.form.gender = optionsGender;
          this.isOtherGender = true;
        }
      }
    }

    this.getAddress();
  },

  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  methods: {
    async getAddress() {
      if (this.form.province) {
        let id = this.province.find((x) => x.name == this.form.province).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${1}`
        );
        this.district = res.detail.district;
        await this.$nextTick();
      }

      if (this.form.district) {
        let id = this.district.find((x) => x.name == this.form.district).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${2}`
        );
        this.subdistrict = res.detail.subdistrict;
        await this.$nextTick();
      }
    },
    async save() {
      if (this.form.birthday) {
        const date = new Date(this.form.birthday);

        this.birthday = {
          birthday_day: String(date.getDate()).padStart(2, "0"),
          birthday_month: String(date.getMonth() + 1).padStart(2, "0"),
          birthday_year: date.getFullYear(),
        };
      }

      let dynamic = [];
      for (const fields of this.dynamicField) {
        let target_id = 0;
        if (fields.field_choices.length > 0) {
          const findFieldId = fields.field_choices.find(
            (choice) => choice.name == fields.user_answer
          );
          if (findFieldId) {
            target_id = findFieldId.id;
          }
        }
        dynamic.push({
          id: fields.id,
          value: fields.user_answer,
          target_id: target_id,
        });
      }

      let objTemp = [];

      for (const x of dynamic) {
        var findField = this.dynamicField.find((field) => field.id == x.id);

        if (x.value && typeof x.value == "object") {
          if (x.value.length > 0) {
            for (const a of x.value) {
              const { id } = findField.field_choices.find(
                (choice) => choice.name == a
              );
              objTemp.push({
                id: x.id,
                value: a,
                target_id: id,
              });
            }
          }
        } else {
          objTemp.push(x);
        }
      }

      this.$v.form.$touch();

      if (this.$v.form.$error) {
        this.tabIndex = 0;
        return;
      }
      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = {
        ...this.form,
        ...this.birthday,
        prefix: this.otherPrefix || this.form.prefix,
        gender: this.otherGender || this.form.gender,
        custom_field: objTemp.filter((el) => el.value),
      };
      body.telephone ? body.telephone.trim() : "";
      body.email ? body.email.trim() : "";
      if (this.isEdit) {
        await this.axios
          .post(`${this.$baseUrl}/customer/registerform/EditUserProfile`, body)
          .then((data) => {
            this.$store.dispatch("setMainLoading", false);
            if (data.result == 1) {
              this.successAlert();
              this.$emit("success");
              this.isLoading = false;
            } else {
              this.warningAlert(data.message);
            }
          });
      } else {
        await this.axios
          .post(`${this.$baseUrl}/customer/registerform/Register`, body)
          .then((data) => {
            this.$store.dispatch("setMainLoading", false);
            if (data.result == 1) {
              this.successAlert();
              this.$emit("success");
              this.isLoading = false;
            } else {
              this.warningAlert(data.message);
            }
          });
      }
    },

    handleEnter(isPost) {
      if (isPost) {
        this.$refs.postNum.$el.querySelector("input").blur();
      } else {
        this.$refs.lineId.$el.querySelector("input").blur();
      }
    },
    setValue(val, key) {
      this.form[key] = val.target.value;
    },

    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      if (val == 99) val = 22;
      const findField = fieldInForm2.find((el) => el.id == val);
      return findField ? findField.key : false;
    },
    addTel() {
      this.$emit("AddOptionalTelephone");
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        (e.target.type == "number" || e.target.type == "Number")
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    handleSelected(val, field, options) {
      if (field == "prefix") {
        let other = options.find((x) => x.choice_type_id == 3);

        let otherName = other ? other.name : "";
        this.form.prefix = val;
        if (val == otherName) {
          this.isOtherPrefix = true;
        } else {
          this.otherPrefix = "";
          this.isOtherPrefix = false;
        }
      } else {
        let other = options.find((x) => x.choice_type_id == 3);
        let otherName = other ? other.name : "";

        this.form.gender = val;
        if (val == otherName) {
          this.isOtherGender = true;
        } else {
          this.otherGender = "";
          this.isOtherGender = false;
        }
      }
    },
    handleChangeCountry(val) {
      this.form.country = val;
      if (val === "ประเทศไทย") return;
      else {
        this.form.zip_code = null;
        this.form.province = null;
        this.form.town = null;
        this.form.alley = null;
        this.form.road = null;
        this.form.subdistrict = null;
        this.form.district = null;
      }
    },
    checkRequired(field) {
      if (
        field == "last_name_th" ||
        field == "first_name_th" ||
        field == "telephone" ||
        field == "birthday"
      )
        return true;
    },
    async handleChange(val, field) {
      if (field == "province") {
        this.form.province = val;
        let id = this.province.find((x) => x.name == val).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${1}`
        );
        this.district = res.detail.district;
        this.form.district = null;
        this.form.subdistrict = null;
        this.form.zip_code = null;
      } else if (field == "district") {
        this.form.district = val;
        let id = this.district.find((x) => x.name == val).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${2}`
        );
        this.subdistrict = res.detail.subdistrict;
        this.form.subdistrict = null;
        this.form.zip_code = null;
      } else {
        this.form.subdistrict = val;
        let zip_code = this.subdistrict.find((x) => x.name == val).zip_code;
        this.form.zip_code = zip_code;
      }
    },
    changeName($fields) {
      return $fields.field_language[0].name || $fields.name;
    },

    async setImageName(val, id) {
      let res = await this.checkIsProfile(id);
      if (res == "id_card_image") this.form.id_card_file_name = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-box {
  max-height: 500px;
  overflow: auto;
}

::v-deep .custom-select:disabled {
  background-color: #d8dbe0 !important;
}

::v-deep input:disabled {
  background-color: #d8dbe0 !important;
}
.text-link {
  font-size: 14px !important;
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-color) !important;
}

label {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

::v-deep .vdatetime-input {
  border-radius: 10px !important;
  /* padding: 8px 10px; */

  height: 40px !important;
}

.border-red {
  ::v-deep .vdatetime-input {
    border-color: red !important;
  }
}
.calendar-icon {
  position: absolute;
  top: 50%;
  right: 3%;
}
.calendar-icon-top{
  top: 35% !important;
}
.datetime-container {
  position: relative;
}
</style>
